input {
  padding: 10px 90px 10px 10px;
  /* border: 1px solid red; */
  border-radius: 40px;
}
input.phone {
  padding: 10px 40px 10px 10px;
  border: 1px solid lightgray;
  border-radius: 40px;
}
input.google {
  padding: 7px 65% 7px 10px;
  border: 1px solid lightgray;
  border-radius: 0;
}
#textarea {
  border-radius: 15px;
  width: 100%;
  padding: 20px 0 0 10px;
}

.red {
  color: red;
  padding: 20px;
}
.btn-style {
  background: orange;
  padding: 10px;
  color: white;
  border: none;
  font-weight: bolder;
}
