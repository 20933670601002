
.bg-image{
    background-image: url('../Assetts/Images/slider-1.jpg');
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-size: cover;
}

.Data{
    width: 100%;
}

.Data h1{
    text-align: center;
    margin: 4rem 0 2rem 0;
    color: orange;
}
.Data p{
    text-align: center;
    color: white;
    font-size: 1rem;
    margin: 0 4rem;
}

.card-container{
    max-width: 900px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
}
.card-content{
    background: transparent;
    text-align: center;
    padding: 1rem;
}    
.card-content:hover{
    opacity: 0.7;
    border-bottom: 3px solid #1761A0;
}
.card-content h3{
    font-size: 1.4rem;
    color: white;
}

@media screen and (max-width: 740px){
    .card-container{
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .card-content h3{
        font-size: .8rem;
        color: white;
    }
}


