.content-bg-image {
  background-image: url("../Assetts/Images/Home-main.jpg");
}

.carousel-indicators .circle-indicator {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 12px;
  height: 12px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.content {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: auto;
  width: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.hero {
  width: 100%;
  height: 100%;
}
.hero .content {
  top: 50%;
  left: 50%;
  text-align: center;
}
.content-heading {
  font-size: 2rem;
  padding: 0.6rem 0 1.5rem;
  color: orange;
  font-weight: 700;
}

.content p {
  font-size: 1rem;
  font-weight: 400;
  color: white;
}
@media screen and (max-width: 640px) {
  .content h1 {
    font-size: 1rem;
  }

  .hero .content p {
    font-size: 0.8rem;
  }
}
