.header-content h3 {
  display: flex;
  justify-content: center;
  padding: 80px 20px 16px;
  font-size: 2.5rem;
  color: orange;
}
.center {
  text-align: center;
  height: 390px;
  background: black;
  /* background: rgb(243, 237, 237); */
}

@media only screen and (max-width: 768px) {
  .header-content h3 {
    font-size: 2rem;
  }
  .content-paragraph{
    font-size: 15px;
  }
}
@media only screen and (max-width: 400px) {
  .header-content h3 {
    font-size: 1rem;
  }
  .center {
    height: 460px;
  }
}
